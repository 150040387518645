<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">
        Etes-vous sûr de vouloir supprimer la relation "{{ partnerView }}" / "{{
          brand
        }}" ?
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="deleteRelation"
                  >Supprimer
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialog', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";

export default {
  name: "DelConfirmation",
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    partnerView: {
      type: String,
      required: true,
    },
    brand: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteRelation() {
      const { status } = await axios.delete(`/partners/relation/${this.id}`);
      if (status === 204) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `La relation ${this.partnerView} / ${this.brand} a été supprimée.`,
          color: "success",
          timeout: NOTIF_DISPLAY_DURATION,
        });

        setTimeout(() => this.$emit("removedItem"), NOTIF_DISPLAY_DURATION);
      }
    },
  },
};
</script>

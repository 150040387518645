var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v("Liste des relations")]),_c('v-card',{staticClass:"mb-6 pa-2",attrs:{"outlined":""}},[_c('filters')],1),_c('v-data-table',{attrs:{"dense":"","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"disable-sort":"","options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 25, 50, 100],
    },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
    var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"to":("/admin/partner/relation/edit/" + (item.id)),"elevation":"0","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":""},nativeOn:{"click":function($event){_vm.dialogModel.visible = !_vm.dialogModel.visible;
            _vm.dialogModel.id = item.id;
            _vm.dialogModel.partnerView = item.partner_view_name;
            _vm.dialogModel.brand = item.brand_name;}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialogModel.visible),callback:function ($$v) {_vm.$set(_vm.dialogModel, "visible", $$v)},expression:"dialogModel.visible"}},[_c('del-confirmation',{attrs:{"dialogOpen":_vm.dialogModel.visible,"id":_vm.dialogModel.id,"partnerView":_vm.dialogModel.partnerView,"brand":_vm.dialogModel.brand},on:{"openDialog":_vm.openDialog,"removedItem":_vm.refreshWindow}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les marques"
    multiple
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "BrandFilter",
  data() {
    return {
      items: [],
    };
  },
  async created() {
    this.items = await this.$store.dispatch("common/getSiteBrandList");
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters["admin/getBrands"];
      },
      set(e) {
        this.$store.dispatch("admin/updateBrands", e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>

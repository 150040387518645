<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les vues partenaires"
    multiple
    small-chips
    deletable-chips
    item-text="label"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: "PartnerViewFilter",
  props: {
    initialValue: {
      type: Array,
      validator(values) {
        return (
          values.length === 0 ||
          values.every((value) => typeof value === "number")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["admin"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getPartnerViews",
    },
    storeUpdater: {
      type: String,
      default: "updatePartnerViews",
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async created() {
    this.items = await this.$store.dispatch("common/getPartnerViewList");
  },
  computed: {
    useStore() {
      return this.store !== undefined;
    },
    selected: {
      get() {
        if (this.useStore) {
          return this.$store.getters[`${this.store}/${this.storeGetter}`];
        }

        return this.initialValue;
      },
      set(e) {
        if (this.useStore === false) {
          if (e !== this.initialValue) {
            this.$emit("valueChanged", e);
          }

          return;
        }

        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>

<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="5">
        <brand-filter />
      </v-col>
      <v-col cols="5">
        <partner-view-filter store="admin" />
      </v-col>
      <v-col cols="2"></v-col>

      <v-col cols="5">
        <billing-filter />
      </v-col>
      <v-col cols="5">
        <cashing-filter />
      </v-col>
      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import BillingFilter from "@/components/admin/partner/relations/filters/BillingFilter";
import BrandFilter from "@/components/admin/partner/relations/filters/BrandFilter";
import CashingFilter from "@/components/admin/partner/relations/filters/CashingFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    BillingFilter,
    BrandFilter,
    CashingFilter,
    PartnerViewFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
